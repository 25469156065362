import { navigate } from '@reach/router';
import axios from 'axios';
import React from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents
import { Col, Container, Row } from 'react-grid-system';
// Local UI components

import FirstSectionDetails from '../../page-components/ProfileDetails/FirstSectionDetails';
import SecondSectionDetails from '../../page-components/ProfileDetails/SecondSectionDetails';
import { ProfileDetailsEndPoint } from '../../shared/APIs';
import PageLayout from '../../shared/PageLayout';
import Seo from '../../shared/Seo';
import { getToken } from '../../shared/Services/auth';
import Loader from '../../shared/UIKit/Loader';

const ProfileDetailsPage = ({ name }) => {
  /* ********************************** HOOKS ********************************* */

  const locale = 'fr';
  // state
  const [profileDetails, setProfileDetails] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  // Effect
  async function getProfileDetails() {
    try {
      if (name === undefined) return;
      setIsLoading(true);
      const { data } = await axios.get(ProfileDetailsEndPoint(name, locale), {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      setProfileDetails(data.data[0]);
    } catch (error) {
      console.log(error);
      navigate('/profiles');
    }
    setIsLoading(false);
  }

  React.useEffect(() => {
    getProfileDetails();
  }, []);

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Profile details" />
      <Container className="profile-details">
        {isLoading ? (
          <Loader />
        ) : (
          <Row>
            <Col xxl={3} xl={4} lg={4} md={12} sm={12} xs={12}>
              <FirstSectionDetails
                profileId={profileDetails?.id}
                profileName={profileDetails?.attributes?.profileName}
                centerLocation={profileDetails?.attributes?.center}
                skills={profileDetails?.attributes?.profileSkills}
                score={profileDetails?.attributes?.score}
                imageUrl={
                  profileDetails?.attributes?.profileImage?.data?.attributes.url
                }
              />
            </Col>
            <Col xxl={9} xl={8} lg={8} md={12} sm={12} xs={12}>
              <SecondSectionDetails
                profileDesc={profileDetails?.attributes?.profileDescription}
                experiences={profileDetails?.attributes}
                speciality={profileDetails?.attributes?.jobTitle}
              />
            </Col>
          </Row>
        )}
      </Container>
    </PageLayout>
  );
};

ProfileDetailsPage.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ProfileDetailsPage;
